.header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

li {
    list-style: none;
}



.brand {
    grid-area: 2 / 2 / 3 / 3;
}



.who {
    grid-area: 1 / 2 / 3 / 3; 
}

.headshot {
    grid-area: 1 / 1 / 3 / 2;
}

.headshotImg {
        border-radius: 5rem;  
        padding-right: 10px;
}

.social {
    height: 50px;
    width: 50px;
}

.passion {
    grid-area: 1 / 3 / 3 / 4;}

.skills {
    grid-area: 3 / 2 / 4 / 3;
}

.contact {
    grid-area: 4 / 2 / 5 / 3;
}
