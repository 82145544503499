.project {
  display: flex;
  align-items: center;
  border: 2px solid #704f38;
  border-radius: 1rem;

  margin: 10px;
  p {
    position: relative;
    margin: 50px;
    width: 60%;
  }
}

.screenshot {
  margin: 1rem;
  width: 40%;
  border-radius: 1rem;
  position: relative;
  border: 2px solid #704f38;
}

h1 {
  text-align: center;
}

.link {
  margin: auto;
  display: flex;
  justify-content: center;
  margin: 20px;
  font-size: x-large;
  color: #704f38;
}
